<template>
  <div class="page Instructions">
    <h1>
      易企呼线索主要来源是互联网公开过的企业信息，如公司官网，工商年报，招聘网站，各类B2B网站信息等等，根据套餐分为日导出2500，5000，10000，20000，50000五个套餐，套餐有效期均为一年有效，用户可根据实际需求选择套餐，如果一个套餐不够使用，可订购多个套餐。
    </h1>
    <div class="box-item" v-for="item in list" :key="item.id">
      <!-- <h1>1，总菜单-易企呼线索A-易企呼线索A-添加账号（选择需要套餐）</h1>
      <img src="http://mg.400fzy.com/public/images/explain.jpg" /> -->
      <h1>{{ item.title }}</h1>
      <img :src="item.src" />
      <img style="margin-top:15px" v-if="item.id === 6" :src="item.src2" />
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "Instructions",
  setup() {
    const list = ref([
      {
        id: 1,
        title: "1，总菜单-易企呼线索A-易企呼线索A-添加账号（选择需要套餐）",
        src: "http://mg.400fzy.com/public/images/explain.jpg",
      },
      {
        id: 2,
        title: "2，总菜单-易企呼线索A-易企呼线索A-启用套餐",
        src: "http://mg.400fzy.com/public/images/explain1.jpg",
      },
      {
        id: 3,
        title: "3，总菜单-易企呼线索A-易企呼线索A-添加账号（选择需要套餐）",
        src: "http://mg.400fzy.com/public/images/explain2.jpg",
      },
      {
        id: 4,
        title: "4，绑定手机号码（第一次启用套餐需要绑定手机号码）",
        src: "http://mg.400fzy.com/public/images/explain3.jpg",
      },
      {
        id: 5,
        title: "5，按选择的调整筛选出数据，点击导出",
        src: "http://mg.400fzy.com/public/images/explain4.jpg",
      },
      {
        id: 6,
        title:
          "6，按需要条件设置好后，选择需要导入数据的外呼任务和条数，点击确定",
        src: "http://mg.400fzy.com/public/images/explain5.jpg",
        src2: "http://mg.400fzy.com/public/images/explain6.jpg",
      },
      {
        id: 7,
        title: "7，数据导出完成，启动任务即可外呼",
        src: "http://mg.400fzy.com/public/images/explain7.jpg",
      },
      {
        id: 8,
        title:
          "8，数据导出：易企呼线索A-导出记录，按大数据筛选时间段选择需要的数据，点击右上角导出功能，即可将数据导出到Excel",
        src: "http://mg.400fzy.com/public/images/explain8.jpg",
      },
    ]);
    return { list };
  },
};
</script>

<style scoped>
.Instructions {
  padding: 15px;
  overflow: auto;
}
.box-item {
  text-align: center;
  margin-bottom: 35px;
}
</style>